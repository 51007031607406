import { IControllerConfig, IWixAPI } from '@wix/yoshi-flow-editor';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
} from '../../consts';
import { getRequestedPageFromQueryParam } from '../../utils/pagination/pagination';

export const onLocationURLChange = (
  wixCodeApi: IWixAPI,
  callback: Function,
) => {
  const path = wixCodeApi.location.path;
  let currentPage = getRequestedPageFromQueryParam(wixCodeApi);
  wixCodeApi.location.onChange((data: { path: string[] }) => {
    const newPage = getRequestedPageFromQueryParam(wixCodeApi);
    if (data.path[0] === path[0] && newPage === currentPage) {
      callback();
    }
    currentPage = newPage;
  });
};

export const getPresetId = (
  config: IControllerConfig,
  isEditorX: boolean,
): string =>
  config.publicData.COMPONENT?.presetId ||
  (isEditorX
    ? BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID
    : BOOKINGS_MAIN_PAGE_PRESET_ID);
