import {
  WidgetViewModel,
  createWidgetErrorStateViewModel,
} from '../../viewModel/viewModel';
import { BookingsAPI } from '../../api/BookingsApi';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getEnrichedServicesAndAvailability } from '../../utils/getEnrichedServicesAndAvailability/getEnrichedServicesAndAvailability';
import { bookingsUoULoadMorePreviousServicesButtonClickSrc16Evid1222 } from '@wix/bi-logger-bookings-data/v2';
import { ServiceListLoadButtonName } from '../../utils/bi/consts';
import { getLoadingButtonsText } from '../../viewModel/bodyViewModel/bodyViewModel';

export type LoadServicesByPageAction = (requestedPage: number) => void;

export const createLoadServicesByPageAction =
  ({
    widgetViewModel,
    bookingsApi,
    flowAPI,
    activeFeatures,
    isPricingPlanInstalled,
    serviceListContext,
    setProps,
  }: {
    widgetViewModel: WidgetViewModel;
    bookingsApi: BookingsAPI;
    setProps: Function;
    flowAPI: ControllerFlowAPI;
    activeFeatures: any;
    isPricingPlanInstalled: boolean;
    serviceListContext: any;
  }): LoadServicesByPageAction =>
  async (requestedPage) => {
    const { services: currentServices, servicesPagingMetadata } =
      widgetViewModel;

    if (!servicesPagingMetadata) {
      return;
    }

    const { totalPages, maxPageLoaded, minPageLoaded } = servicesPagingMetadata;

    const shouldLoadPreviousPage =
      requestedPage < minPageLoaded && minPageLoaded > 1;

    const shouldLoadNextPage =
      requestedPage > maxPageLoaded && maxPageLoaded < totalPages;

    flowAPI.bi?.report(
      bookingsUoULoadMorePreviousServicesButtonClickSrc16Evid1222({
        pageName: flowAPI.componentName!,
        buttonName: shouldLoadNextPage
          ? ServiceListLoadButtonName.NEXT
          : ServiceListLoadButtonName.PREVIOUS,
      }),
    );
    if (shouldLoadPreviousPage || shouldLoadNextPage) {
      try {
        const { services: enrichedLoadedServices, coursesAvailability } =
          await getEnrichedServicesAndAvailability({
            flowAPI,
            activeFeatures,
            isPricingPlanInstalled,
            isAnywhereFlow: serviceListContext.isAnywhereFlow,
            bookingsApi,
            requestedPage,
          });

        widgetViewModel.coursesAvailability = {
          ...widgetViewModel.coursesAvailability,
          ...coursesAvailability,
        };
        widgetViewModel.services = shouldLoadNextPage
          ? [...currentServices, ...enrichedLoadedServices]
          : [...enrichedLoadedServices, ...currentServices];

        widgetViewModel.servicesPagingMetadata = {
          totalPages,
          maxPageLoaded: shouldLoadNextPage ? requestedPage : maxPageLoaded,
          minPageLoaded: shouldLoadPreviousPage ? requestedPage : minPageLoaded,
        };

        const { loadPreviousButtonText, loadMoreButtonText } =
          getLoadingButtonsText({
            flowAPI,
            servicesPagingMetadata: widgetViewModel.servicesPagingMetadata,
          });

        setProps({
          widgetViewModel: {
            ...widgetViewModel,
            bodyViewModel: {
              ...widgetViewModel.bodyViewModel,
              loadPreviousButtonText,
              loadMoreButtonText,
            },
          },
        });
      } catch (error) {
        console.error(error);

        setProps({
          widgetErrorStateViewModel: createWidgetErrorStateViewModel({
            flowAPI,
          }),
        });
      }
    }
  };
